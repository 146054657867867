<form *ngIf="form"
      (ngSubmit)="runDataLoad()"
      [formGroup]="form">

  <div class="content">
    <mat-error *ngFor="let err of globalErrors$ | async">&bull; {{err}}</mat-error>
  </div>
  <div class="content tw-flex tw-flex-col tw-items-start">

    <div>
      <mat-selection-list formControlName="selectedApps"
                          required>
        <div mat-subheader>
          Tables to Load
        </div>
        <mat-list-option checkboxPosition="before"
                         value="CrossRefImport">Cross Ref</mat-list-option>
        <mat-list-option checkboxPosition="before"
                         value="DealerImport">Dealer</mat-list-option>
        <mat-list-option checkboxPosition="before"
                         value="DocumentImport">Documents</mat-list-option>
        <mat-list-option checkboxPosition="before"
                         value="PartImageImport">Part Images</mat-list-option>
        <mat-list-option checkboxPosition="before"
                         value="PartsImport">Parts</mat-list-option>
        <mat-list-option checkboxPosition="before"
                         value="ProductLinesImport">Product Lines</mat-list-option>
        <mat-list-option checkboxPosition="before"
                         value="AuditImport">Audit Files</mat-list-option>
      </mat-selection-list>
      <mat-error [depot-form-error]="form"
                 errorControlName="selectedApps">
      </mat-error>
    </div>

    <div>
      <button type="button"
              (click)="uploadInput.click()"
              mat-raised-button
              color="primary"
              style="margin: 0 16px;">
        Select Documents File
      </button>

      <label>{{form.get('documentFile').value?.name}}</label>
      <input type="file"
             #uploadInput
             accept=".zip"
             style="display: none"
             (change)="onFileSelected($event.target.files)" />
      <mat-error [depot-form-error]="form"
                 errorControlName="documentFile">
      </mat-error>
    </div>
    <br />

    <div class="tw-grid tw-grid-flow-row tw-auto-rows-max ">
      <div class="tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 table-count-header">
        Table Counts
        <button mat-icon-button
                type="button"
                matTooltip="Reload Counts"
                matTooltipPosition="below"
                (click)="reloadTableCounts()">
          <span class="fa fa-redo"></span>
        </button>

      </div>

      @if(tableCounts$ | async; as tableCounts){
      <div class="tw-grid xs:tw-grid-cols-1 md:tw-grid-cols-2 lg:md:tw-grid-cols-5">

        <div class="tw-px-4 tw-py-2 tw-text-center dp-grid-tile">
          <span style="font-weight: 900;">Parts</span>
          <div style="flex-grow: 1;"></div>
          {{tableCounts.partCount | number}}
        </div>
        <div class="tw-px-4 tw-py-2 tw-text-center dp-grid-tile">
          <span style="font-weight: 900;">Dealer</span>
          <div style="flex-grow: 1;"></div>
          {{tableCounts.dealerCount | number}}
        </div>
        <div class="tw-px-4 tw-py-2 tw-text-center dp-grid-tile">
          <span style="font-weight: 900;">Product Lines</span>
          <div style="flex-grow: 1;"></div>
          {{tableCounts.productLineCount | number}}
        </div>
        <div class="tw-px-4 tw-py-2 tw-text-center dp-grid-tile">
          <span style="font-weight: 900;">Cross Refs</span>
          <div style="flex-grow: 1;"></div>
          {{tableCounts.partsCrossRefCount | number}}
        </div>
        <div class="tw-px-4 tw-py-2 tw-text-center dp-grid-tile">
          <span style="font-weight: 900;">Documents</span>
          <div style="flex-grow: 1;"></div>
          {{tableCounts.documentCount | number}}
        </div>

      </div>
      }@else {
      <div class="tw-grid tw-grid-cols-1 tw-px-4 tw-py-2">
        <mat-spinner class="tw-place-self-center"
                     [diameter]="45"
                     [strokeWidth]="5"
                     color="accent"
                     mode="indeterminate"></mat-spinner>
      </div>
      }

    </div>

  </div>

  <div class="dp-top-fab-container">

    <button [disabled]="form?.valid === false"
            type="submit"
            mat-mini-fab
            matTooltip="Run Program"
            matTooltipPosition="below"
            color="accent">
      <span class="fa fa-save"></span>
    </button>
  </div>

</form>
