import { Location } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import {
  AuthService,
  CancelRequestService,
  HelperService,
  Json2Table,
  SettingsService,
  StackFormat,
  WindowWrapper
} from '@depot/@common';
import { ErrorRepositoryService, IErrorSearch } from '@depot/@data';
import { LogDataSource } from '@depot/admin';
import { IJsonLog } from '@depot/custom';

import { BehaviorSubject, fromEvent, Observable } from 'rxjs';

import { isMatch } from 'underscore';

@Component({
  selector: 'depot-log-grid',
  templateUrl: './log-grid.component.html',
  styleUrls: ['./log-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogGridComponent implements OnInit, AfterViewInit, OnDestroy {
  public dataSource = new LogDataSource(this.errorRepo);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatExpansionPanel) searchPanel: MatExpansionPanel;
  @ViewChild(MatDrawerContainer) sideBar: MatDrawerContainer;
  @ViewChild('output') private output: any;
  public cachedFilters$ = new BehaviorSubject<IErrorSearch>({});
  public selectedRow$ = new BehaviorSubject<number>(-1);
  public isAutoScroll = true;
  public applications$: Observable<string[]>;

  get defaultFilters(): IErrorSearch {
    return {
      startDate: null,
      endDate: null,
      message: null,
      applications: null,
      levels: null,
    };
  }


  constructor(
    private errorRepo: ErrorRepositoryService,
    private helper: HelperService,
    private settingsService: SettingsService,
    private authService: AuthService,
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private window: WindowWrapper,
    private location: Location,
    private httpCancelService: CancelRequestService,
    private destroyRef: DestroyRef,
  ) {

    this.dataSource.isLoading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(x => this.helper.IsGlobalSpinner$.set(x));
    this.helper.IsGlobalBar$.set(true);
  }

  ngOnInit() {
    this.dataSource.totalRows$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((rows: number) => this.paginator.length = rows);

    this.applications$ = this.errorRepo.getApplicationList();
    this.settingsService.dashboardSettings<IErrorSearch>(this.dataSource.filterName).then(cachedData => {

      if (!cachedData) {
        cachedData = this.defaultFilters;
      }

      this.dataSource.data$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((logs: IJsonLog[]) => {
          const id = this.activatedRoute.snapshot.params['id'];
          if (id) {
            const data = logs.filter(x => x.id === id);
            if (data && data.length > 0) {
              this.onRowSelect(data[0], logs.indexOf(data[0]));
            }
          }
        });

      if (!isMatch(cachedData, this.defaultFilters)) {
        this.searchPanel.open();
      }
      cachedData.pageIndex = this.paginator.pageIndex;
      cachedData.takeCount = cachedData.takeCount > 0 ? cachedData.takeCount : this.paginator.pageSizeOptions[0];
      this.paginator.pageSize = cachedData.takeCount;
      this.paginator.pageIndex = cachedData.pageIndex;

      this.cachedFilters$.next(cachedData);
      this.loadData(null);
    });
  }

  ngAfterViewInit() {
    fromEvent(document, 'keyup')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x: KeyboardEvent) => {
        x.preventDefault();
        if (x && (x.key === 'ArrowLeft' || x.key === 'ArrowUp')) {
          this.onErrorNav(-1);
        } else if (x && (x.key === 'ArrowRight' || x.key === 'ArrowDown')) {
          this.onErrorNav(1);
        }
      });

  }

  public async loadData(e?: Event | null) {
    this.httpCancelService.cancelPendingRequests();
    if (e) {
      e.stopPropagation();
      this.paginator.pageIndex = 0;
    }
    let sortInput = '';
    if (this.sort.active && this.sort.direction) {
      sortInput = this.sort.active + ' ' + this.sort.direction;
    }

    const values = this.cachedFilters$.getValue();
    values.takeCount = this.paginator.pageSize;
    values.pageIndex = this.paginator.pageIndex;
    values.orderBy = sortInput;
    const cachedData = await this.settingsService.dashboardSettings<IErrorSearch>(this.dataSource.filterName, values);

    this.dataSource.loadData(cachedData);
    this.helper.IsGlobalBar$.set(false);
  }

  public resetFilters(e?: Event) {
    if (e) {
      e.stopPropagation();
    }
    this.cachedFilters$.next(this.defaultFilters);
    this.paginator.firstPage();
    this.loadData();
  }

  public sortFilters() {
    this.paginator.firstPage();
    this.loadData();

  }

  ngOnDestroy(): void {

    this.cachedFilters$.complete();
    // this.depotContext.close();
  }

  onRowSelect(row: IJsonLog, index: number) {
    this.selectedRow$.next(index);
    const data = JSON.parse(row.body);
    // this.router.navigate([this.router.url, row.id]);
    if (data.isClient === true && data.stackTrace) {
      data.stackTrace = StackFormat.jsStack(data.stackTrace);

    } else if (data.exception) {
      data.exception = StackFormat.netStack(data.exception);
    }
    this.output.nativeElement.innerHTML = Json2Table.buildTable(data).outerHTML;
    this.location.go('/admin/logs/' + row.id);
    this.sideBar.open();
  }

  onPanelClose() {
    this.location.go('/admin/logs');
  }

  // viewJson() {
  //   const datasource = this.dataSource.data$.getValue();
  //   const body = datasource[this.selectedRow$.getValue()].body;
  //   this.output.nativeElement.innerHTML = '<pre>' + JSON.stringify(JSON.parse(body), null, 4) + '</pre>';
  // }

  onErrorNav(increment: number) {
    const datasource = this.dataSource.data$.getValue();
    const nextIndex = this.selectedRow$.getValue() + increment;
    const data = datasource[nextIndex];

    if (data) {
      this.onRowSelect(data, nextIndex);
      if (this.isAutoScroll) {
        this.window.document.getElementById('output').scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }

}

