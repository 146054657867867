import { Location } from '@angular/common';
import { Component, DestroyRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';

import { CancelRequestService, HelperService, SettingsService } from '@depot/@common';
import { EmailLogRepositoryService, IEmailLogSearch } from '@depot/@data';
import { EmailLogGridDataSource, EmailViewComponent } from '@depot/admin';
import { IEmailLog } from '@depot/custom';

import { BehaviorSubject, filter, first } from 'rxjs';

import { isMatch } from 'underscore';

@Component({
  selector: 'depot-email-grid',
  templateUrl: './email-grid.component.html',
  styleUrls: ['./email-grid.component.scss']
})
export class EmailGridComponent implements OnInit, OnDestroy {
  public dataSource = new EmailLogGridDataSource(this.emailLogService);
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatExpansionPanel) searchPanel: MatExpansionPanel;
  public cachedFilters$ = new BehaviorSubject<IEmailLogSearch>({});
  public hasFilters$ = new BehaviorSubject<boolean>(false);
  get defaultFilters(): IEmailLogSearch {
    return {
      startDate: null,
      endDate: null,
      body: null,
      subject: null,
      wasSuccessful: '',
    };
  }
  public columns = ['edit', 'subject', 'toAddress', 'attachmentCount', 'wasSuccessful', 'createdDate'];
  private get filterName() { return 'email_log_grid'; }

  constructor(
    private helper: HelperService,
    private emailLogService: EmailLogRepositoryService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog,
    private settingsService: SettingsService,
    private httpCancelService: CancelRequestService,
    private destroyRef: DestroyRef,
  ) {
    this.dataSource.isLoading$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(x => this.helper.IsGlobalSpinner$.set(x));
    const id = Number(this.activatedRoute.snapshot.params['id']);

    if (id) {
      this.dataSource.connect()
        .pipe(
          filter(x => x?.length > 0),
          first())
        .subscribe(users => {
          const selectedUser = users.find(x => x.id === id);
          if (selectedUser) {
            this.navigate(selectedUser);
          }
        });
    }
  }

  ngOnInit() {
    this.dataSource.totalRows$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((rows: number) => this.paginator.length = rows);

    this.settingsService.dashboardSettings<IEmailLogSearch>(this.filterName).then(cachedData => {

      if (!cachedData) {
        cachedData = this.defaultFilters;
      }
      if (!isMatch(cachedData, this.defaultFilters)) {
        this.searchPanel.open();
      }
      cachedData.pageIndex = this.paginator.pageIndex;
      cachedData.takeCount = cachedData.takeCount > 0 ? cachedData.takeCount : this.paginator.pageSizeOptions[0];
      this.paginator.pageSize = cachedData.takeCount;
      this.paginator.pageIndex = cachedData.pageIndex;
      this.cachedFilters$.next(cachedData);
      this.loadData(null);
    });

  }

  public async loadData(e?: Event | null) {
    this.httpCancelService.cancelPendingRequests();
    if (e) {
      e.stopPropagation();
    }
    let sortInput = '';
    if (this.sort.active && this.sort.direction) {
      sortInput = this.sort.active + ' ' + this.sort.direction;
    }

    const values = this.cachedFilters$.getValue();
    values.takeCount = this.paginator.pageSize;
    values.pageIndex = this.paginator.pageIndex;
    values.orderBy = sortInput;

    const cachedData = await this.settingsService.dashboardSettings<IEmailLogSearch>(this.filterName, values);

    this.dataSource.loadData(cachedData);
    this.helper.IsGlobalBar$.set(false);

  }


  public resetFilters(e?: Event) {
    if (e) {
      e.stopPropagation();
    }
    this.cachedFilters$.next(this.defaultFilters);
    this.paginator.firstPage();
    this.loadData();
  }

  public sortFilters() {
    this.paginator.firstPage();
    this.loadData();

  }



  ngOnDestroy(): void {

    this.cachedFilters$.complete();
    // this.depotContext.close();
  }
  navigate(emailLog: IEmailLog) {

    if (!emailLog) {
      return;
    }
    this.location.go('/admin/email-logs/' + emailLog.id);

    this.dialog.open(EmailViewComponent, {
      data: emailLog,
      disableClose: true,
      height: '85vh',
      width: '90vw'
    }).afterClosed()
      .pipe(first())
      .subscribe(savedUser => {
        this.location.go('/admin/email-logs');

      });

  }

}
