<div class="fixActionRow">
  <div mat-dialog-content>
    <div class="tw-pb-4">
      <div class="tw-font-extrabold">Subject:</div> {{emailLog.subject}}
    </div>
    <div class="tw-pb-4">
      <div class="tw-font-extrabold">To Address:</div> {{emailLog.toAddress}}
    </div>
    <div class="tw-pb-4">
      <div class="tw-font-extrabold">From Address:</div> {{emailLog.fromAddress}}
    </div>
    <div class="tw-pb-4">
      <div class="tw-font-extrabold">Was Successful:</div> {{emailLog.wasSuccessful ? 'Yes':'No'}}
    </div>
    <div class="tw-pb-4">
      <div class="tw-font-extrabold">Created Date:</div> {{emailLog.createdDate | utcdate:'short'}}
    </div>
    <div class="tw-pb-4">
      <div class="tw-font-extrabold">Created By:</div> {{emailLog.createdBy}}
    </div>
    <div class="tw-pb-4">
      <div class="tw-font-extrabold">Attachments:</div>
      <button mat-raised-button
              (click)="showAttachmentDialog(attach)"
              *ngFor="let attach of emailLog.emailLogAttachments">
        {{attach.name}}
      </button>
    </div>
    <div class="tw-pb-4">
      <div class="tw-font-extrabold">Body:</div>
      <div class="tw-border-2 tw-border-solid tw-border-slate-300 tw-p-2"
           [innerHtml]="emailLog.body | safe:'html'"></div>
    </div>
  </div>
  <div class="spacer"></div>

  <div mat-dialog-actions align="end">

    <button mat-raised-button
            color="primary"
            mat-dialog-close="">Done</button>

  </div>
</div>
