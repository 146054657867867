import { DataSource } from '@angular/cdk/table';

import {
  EmailLogRepositoryService,
  IEmailLogSearch
} from '@depot/@data';
import {
  IEmailLog,
  IPagedResults
} from '@depot/custom';

import { BehaviorSubject, finalize } from 'rxjs';

export class EmailLogGridDataSource extends DataSource<IEmailLog> {
  public isLoading$ = new BehaviorSubject(false);
  public totalRows$ = new BehaviorSubject(0);


  private data$ = new BehaviorSubject<IEmailLog[]>([]);
  constructor(private emailLogRepo: EmailLogRepositoryService) {
    super();

  }
  connect() {
    return this.data$;
  }

  public loadData(filters: IEmailLogSearch) {

    this.isLoading$.next(true);
    this.emailLogRepo.getEmailLogs(filters)
      .pipe(
        // catchError(err => of({ items: [] })),
        finalize(() => this.isLoading$.next(false))
      ).subscribe((x: IPagedResults<IEmailLog>) => {
        this.data$.next(x.items);
        this.totalRows$.next(x.totalCount);
      });
  }

  disconnect(): void {
    this.data$.complete();
    this.isLoading$.next(false);
    this.isLoading$.complete();
    this.totalRows$.complete();
    // this.depotContext.close();
  }

}
