import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { ImageService } from '@depot/@common';
import { IFrameDialogComponent } from '@depot/@components';
import { IEmailLog, IEmailLogAttachment } from '@depot/custom';

import { first } from 'rxjs';

@Component({
  selector: 'depot-email-view',
  templateUrl: './email-view.component.html',
  styleUrls: ['./email-view.component.scss']
})
export class EmailViewComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public emailLog: IEmailLog,
    private dialog: MatDialog,
    private imgService: ImageService,

  ) { }

  ngOnInit(

  ) {
  }

  public showAttachmentDialog(attachment: IEmailLogAttachment) {
    const contentType = this.imgService.getFileContentType(attachment.name);
    if (contentType.native === false) {
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:${contentType.contentType};base64,${attachment.content}`;
      downloadLink.download = attachment.name;
      downloadLink.click();
      return;
    }
    this.dialog.open(IFrameDialogComponent, {
      data: {
        url: `data:${contentType.contentType};base64,${attachment.content}#view=FitH&toolbar=0`,
        fileName: attachment.name,
      },
      minWidth: '60vw',
      minHeight: '70vh',
    }).afterClosed().pipe(first()).subscribe();
    return false;
  }

}
