<mat-drawer-container hasBackdrop="true">
  <mat-drawer class="error_panel"
              closed
              (closedStart)="onPanelClose()"
              mode="over"
              position="end">
    <mat-toolbar color="secondary"
                 id="output"
                 style="top: 0; position: sticky;">
      @let rowIndex = selectedIndex$ | async;
      <button mat-icon-button
              tabindex="-1"
              (click)="showError(null, rowIndex - 1)">
        <span class="fa fa-chevron-left"></span>
      </button>
      <span>Error Row {{ rowIndex + 1 }} of {{ (selectedRows$ | async).length }}</span>
      <!-- <button mat-button (click)="viewJson()">View Json</button> -->
      <button mat-icon-button
              tabindex="-1"
              (click)="showError(null, rowIndex + 1)">
        <span class="fa fa-chevron-right"></span>
      </button>
      <div style="flex: 1 1 auto"></div>
      <mat-slide-toggle class="example-margin"
                        [(ngModel)]="isAutoScroll"
                        labelPosition="before">Auto Scroll</mat-slide-toggle>
      <button mat-icon-button
              tabindex="-1"
              (click)="sideBar.close()">
        <span class="fa fa-times"></span>
      </button>

    </mat-toolbar>
    <div [innerHTML]="jsonTable$ | async"
         class="error_output"></div>
  </mat-drawer>


  <div class="tw-flex tw-flex-col "
       style="margin-top: 15px;">




    <div class="tw-flex tw-items-middle tw-justify-end"
         *ngIf="(cachedFilters$ | async) as cache">

      <mat-form-field>
        <mat-label>Application Filters</mat-label>
        <mat-select [(ngModel)]="cache.application"
                    (selectionChange)="filterGroup()">
          <mat-option value="">- Any -</mat-option>
          <mat-option [value]="val.key"
                      *ngFor="let val of applications$ | async">
            {{val.key}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Date Filters</mat-label>
        <mat-select [(ngModel)]="cache.dateFilter"
                    (selectionChange)="filterChange($event)">
          <!-- <mat-option [value]="1">1 Hour</mat-option> -->
          <!-- <mat-option [value]="2">6 Hours</mat-option> -->
          <mat-option [value]="1">1 Day</mat-option>
          <mat-option [value]="2">1 Week</mat-option>
          <mat-option [value]="3">2 Weeks</mat-option>
          <mat-option [value]="4">1 Month</mat-option>
          <mat-option [value]="5">All</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-icon-button
              (click)="filterChange()">
        <span class="fa fa-redo"></span>
      </button>
    </div>
    <!-- <div style="flex: 1 1 auto">
      <depot-server-settings></depot-server-settings>
    </div> -->
    <ng-container *ngIf="(logData$ | async).length > 0">
      <mat-card appearance="outlined"
                class="tw-mb-4 tw-mx-4 tw-max-w-full tw-flex-1 tw-basis-full">
        <mat-card-content>
          <!-- <div style="width: 100%; height: 150px; ">-->

          <div *ngIf="(cachedFilters$ | async) as cache"
               class="tw-flex tw-flex-row tw-justify-center">
            <div class="overview-box"
                 [class.selected]="cache.filterItems.includes(x.name)"
                 [ngClass]="x.name.toLowerCase() + '-box'"
                 *ngFor="let x of logAggregates$ | async"
                 (click)="filterGroup(x.name)">
              <h2>{{x.value}}</h2>
              <h5>{{x.name}}</h5>
            </div>

          </div>
          <div style="width: 100%; max-height: 500px; min-height: 300px;">
            <ngx-charts-bar-vertical-2d [view]="undefined"
                                        [customColors]="colorScheme"
                                        [xAxis]="true"
                                        [yAxis]="true"
                                        [showGridLines]="true"
                                        [barPadding]="1"
                                        [groupPadding]="10"
                                        (select)="filterGroup($event.name)"
                                        [results]="logChart$ | async">
            </ngx-charts-bar-vertical-2d>
          </div>
        </mat-card-content>
      </mat-card>
      <mat-card appearance="outlined"
                class="tw-mb-4 tw-mx-4 tw-max-w-full tw-flex-1 tw-basis-full">
        <mat-card-content class="tw-flex tw-flex-wrap tw-flex-row">

          <div class="summary-group">
            <h3>Latest</h3>
            <div class="summary-item"
                 *ngFor="let message of (logLatest$ | async) as logs; let i = index;">
              <a [ngClass]="message.level.toLowerCase() + '-label'"
                 style="padding-left: 10px;"
                 (click)="showError(logs, i)">{{message.value.body.message}}</a>
            </div>
          </div>

          <div class="summary-group">
            <h3>Frequent</h3>
            <div class="summary-item"
                 *ngFor="let message of (logFrequent$ | async) as logs; let i = index;">
              <a (click)="showError(logs, i)">
                <div class="summary-count"
                     [ngClass]="message.value.body.level.toLowerCase() + '-label'">{{message.count}}</div>
                {{message.key}}
              </a>
            </div>
          </div>

          <div class="summary-group">
            <h3>Affected Urls</h3>
            <div class="summary-item"
                 *ngFor="let message of (logUrls$ | async) as logs; let i = index;">
              <a (click)="showError(logs, i)">
                <div class="summary-count"
                     [ngClass]="message.value.body.level.toLowerCase() + '-label'">{{message.count}}</div>
                {{message.key}}
              </a>
            </div>
          </div>


          <div class="summary-group">
            <h3>Users</h3>
            <div class="summary-item"
                 *ngFor="let message of (logUsers$ | async) as logs; let i = index;">
              <div class="summary-count"
                   [ngClass]="message.value.body.level.toLowerCase() + '-label'">{{message.count}}</div>
              <span>{{message.key}}</span>
            </div>
          </div>

          <div class="summary-group">
            <h3>Application</h3>
            <div class="summary-item"
                 *ngFor="let message of logApplication$ | async">
              <div class="summary-count"
                   [ngClass]="message.value.body.level.toLowerCase() + '-label'">{{message.count}}</div>
              <span>{{message.key}}</span>
            </div>
          </div>

        </mat-card-content>
      </mat-card>

    </ng-container>
  </div>

  <div class="dp-top-fab-container">

    <a tabindex="-1"
       mat-mini-fab
       matTooltip="Hangfire"
       matTooltipPosition="below"
       color="accent"
       target="_blank"
       href="/v1/hangfire">
      <span class="fa fa-lg dp-icon-hangfire-alt"></span>
    </a>
    <a tabindex="-1"
       mat-mini-fab
       matTooltip="Swagger"
       matTooltipPosition="below"
       color="accent"
       target="_blank"
       href="/v1/swagger">
      <span class="fa fa-lg dp-icon-swagger"></span>
    </a>
    <a tabindex="-1"
       mat-mini-fab
       matTooltip="Health Check"
       matTooltipPosition="below"
       color="accent"
       target="_blank"
       href="/v1/healthchecks-ui">
      <span class="fa fa-lg fa-heartbeat"></span>
    </a>

  </div>


</mat-drawer-container>
