import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { HelperService } from '@depot/@common';
import { AdminRepositoryService } from '@depot/@data';
import { IClientError, ITableCounts } from '@depot/custom';

import { BehaviorSubject, catchError, finalize, of, Subject } from 'rxjs';

@Component({
  selector: 'depot-run-data-load',
  templateUrl: './run-data-load.component.html',
  styleUrls: ['./run-data-load.component.scss']
})
export class RunDataLoadComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public globalErrors$ = new BehaviorSubject<string[]>([]);
  public tableCounts$ = new Subject<ITableCounts>();
  public breakpointCols$ = new BehaviorSubject(2);

  constructor(
    private adminRepo: AdminRepositoryService,
    private helper: HelperService,
    private fb: UntypedFormBuilder,

  ) {
  }
  ngOnInit() {

    this.reloadTableCounts();

    this.form = this.fb.group({
      selectedApps: [[], []],
      documentFile: [null],
    });
    setTimeout(() => {
      this.helper.IsGlobalBar$.set(false);
    });

  }
  public onFileSelected(files: FileList) {
    if (files.length > 0) {
      this.form.patchValue({
        documentFile: files.item(0)
      });
    }

  }
  public runDataLoad() {
    this.globalErrors$.next([]);
    this.helper.IsGlobalSpinner$.set(true);
    const model = this.form.getRawValue();
    this.adminRepo.runDbLoadApp(model)
      .pipe(
        catchError((error: IClientError) => {
          this.helper.mapErrors(error, null, this.form, this.globalErrors$);
          this.helper.logger.error('Error saving message', error);
          return of();
        }),
        finalize(() => this.helper.IsGlobalSpinner$.set(false))
      ).subscribe(() => {
        this.helper.showMessage('Load is running', 'success');
        this.form.reset();
      });
  }

  public reloadTableCounts() {
    this.tableCounts$.next(null);
    this.adminRepo.getTableCounts().subscribe(x => {
      this.tableCounts$.next(x);
    });
  }

  canDeactivate() {
    // if (this.form && this.form.dirty) {
    //   return this.helper.confirmDialog('You have pending changes, do you want to leave and lose those changes?');
    // }
    return true;
  }

  ngOnDestroy(): void {
  }
}
