<div class="content mat-elevation-z8">
  <!--  -->
  <mat-expansion-panel #searchPanel>
    <mat-expansion-panel-header>
      <mat-panel-title class="filter-panel-title">
        Filters
        <button mat-button
                color="primary"
                (click)="loadData($event)">Search</button>
        <button mat-button
                color="primary"
                (click)="resetFilters($event)">Reset</button>

      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent
                 *ngIf="(cachedFilters$ | async) as cache">

      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput
               [ngModel]="cache.startDate"
               (ngModelChange)="cache.startDate = $event"
               [matDatepicker]="startDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix
                               [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput
               [(ngModel)]="cache.endDate"
               [matDatepicker]="endDate">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix
                               [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Subject</mat-label>
        <input matInput
               [(ngModel)]="cache.subject" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Body</mat-label>
        <input matInput
               [(ngModel)]="cache.body" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Was Successful</mat-label>
        <mat-select [(ngModel)]="cache.wasSuccessful">
          <mat-option value="">- Any -</mat-option>
          <mat-option [value]="true">
            Yes
          </mat-option>
          <mat-option [value]="false">
            No
          </mat-option>
        </mat-select>
      </mat-form-field>


    </ng-template>
  </mat-expansion-panel>
  <mat-table [dataSource]="dataSource"
             matSort
             [matSortActive]="(cachedFilters$ | async).orderBy?.split(' ')[0]"
             [matSortDirection]="(cachedFilters$ | async).orderBy?.split(' ')[1]"
             (matSortChange)="sortFilters()">

    <ng-container matColumnDef="edit">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef></mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">

        <a mat-icon-button
           tabindex="-1"
           matTooltip="Edit"
           (click)="navigate(row)">
          <span class="fa fa-file-alt"></span>
        </a>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subject">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Subject</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        {{row.subject}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="toAddress">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>To Address</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        {{row.toAddress}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="attachmentCount">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef>Attachments</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        {{row.emailLogAttachments?.length}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="wasSuccessful">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Was Successful</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        {{row.wasSuccessful ? 'Yes':'No'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdDate">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Create Date</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.createdDate | utcdate:'short'}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns; sticky: true"
                    class="table-header-primary"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns;"></mat-row>

    <div class="no-data-row"
         *matNoDataRow>
      No email logs found
    </div>
  </mat-table>

  <mat-paginator [disabled]="dataSource?.isLoading$ | async"
                 (page)="loadData()"
                 [pageSize]="(cachedFilters$ | async)?.takeCount"
                 [pageSizeOptions]="[25, 50, 250, 500]">
  </mat-paginator>
</div>
