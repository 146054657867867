import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { RoleGuard } from '@depot/@common';
import { DepotCommonModule } from '@depot/@common/depot-common.module';
import { DepotComponentsModule } from '@depot/@components/depot-components.module';
import {
  EmailGridComponent, EmailViewComponent,
  LogDashboardComponent, LogGridComponent,
  RunDataLoadComponent, ServerSettingsComponent,
  UserEditComponent, UserGridComponent
} from '@depot/admin';

import { BarChartModule } from '@swimlane/ngx-charts';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BarChartModule,
    DepotCommonModule,
    DepotComponentsModule,
    RouterModule.forChild([
      {
        path: '',
        redirectTo: '/admin/users',
        pathMatch: 'full'
      },
      {
        path: 'dashboard/:id',
        component: LogDashboardComponent,
        canActivate: [RoleGuard],
        data: {
          isNav: false,
          text: 'Dashboard',
          roles: [RoleGuard.SiteAdmin]
        }
      },
      {
        path: 'dashboard',
        component: LogDashboardComponent,
        canActivate: [RoleGuard],
        data: {
          isNav: false,
          text: 'Dashboard',
          icon: 'fa fa-chart-line',
          roles: [RoleGuard.SiteAdmin]
        }
      },
      {
        path: 'logs/:id',
        component: LogGridComponent,
        canActivate: [RoleGuard],
        data: {
          isNav: false,
          text: 'Logs',
          roles: [RoleGuard.SiteAdmin]
        }
      },
      {
        path: 'logs',
        component: LogGridComponent,
        canActivate: [RoleGuard],
        data: {
          isNav: false,
          text: 'Logs',
          icon: 'fa fa-columns',
          roles: [RoleGuard.SiteAdmin]
        },
      },
      {
        path: 'users',
        component: UserGridComponent,
        canActivate: [RoleGuard],
        data: {
          isNav: false,
          text: 'Users',
          icon: 'fa fa-users',
          roles: [RoleGuard.UserAdmin, RoleGuard.SiteAdmin]
        }
      },
      {
        path: 'users/:id',
        component: UserGridComponent,
        canActivate: [RoleGuard],
        data: {
          title: 'Users',
          icon: 'fa fa-users',
          isNav: false,
          roles: [RoleGuard.UserAdmin, RoleGuard.SiteAdmin]
        }
      },
      {
        path: 'email-logs',
        component: EmailGridComponent,
        canActivate: [RoleGuard],
        data: {
          title: 'Email Logs',
          icon: 'fa fa-envelope',
          isNav: true,
          roles: [RoleGuard.SiteAdmin]
        }
      },
      {
        path: 'email-logs/:id',
        component: EmailGridComponent,
        canActivate: [RoleGuard],
        data: {
          title: 'Email Logs',
          icon: 'fa fa-envelope',
          isNav: false,
          roles: [RoleGuard.SiteAdmin]
        }
      },
      {
        path: 'data-load',
        component: RunDataLoadComponent,
        canActivate: [RoleGuard],
        data: {
          title: 'Data Load',
          icon: 'fa fa-database',
          roles: [RoleGuard.SiteAdmin, RoleGuard.UserAdmin]
        }
      },

    ])
  ],
  declarations: [
    LogDashboardComponent,
    LogGridComponent,
    UserEditComponent,
    UserGridComponent,
    RunDataLoadComponent,
    ServerSettingsComponent,
    EmailGridComponent,
    EmailViewComponent,
  ],
  exports: [
    LogDashboardComponent,
    LogGridComponent,
    UserGridComponent,
    RunDataLoadComponent,
    ServerSettingsComponent,
  ]
})
export class AdminModule { }
