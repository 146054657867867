import { DataSource } from '@angular/cdk/table';

import { ErrorRepositoryService, IErrorSearch } from '@depot/@data';
import { IJsonLog, IPagedResults } from '@depot/custom';

import { BehaviorSubject, finalize } from 'rxjs';

export class LogDataSource extends DataSource<IJsonLog> {
  public get filterName() { return 'errors_grid'; }
  public isLoading$ = new BehaviorSubject(false);
  public totalRows$ = new BehaviorSubject(0);
  public columns = [
    'level',
    'time',
    'application',
    'user',
    'message',
    'isClient',

  ];
  public data$ = new BehaviorSubject<IJsonLog[]>([]);

  constructor(
    private errorRepo: ErrorRepositoryService,
  ) {
    super();
  }
  connect() {

    return this.data$;
  }

  public loadData(filters: IErrorSearch) {
    this.isLoading$.next(true);
    this.errorRepo.searchErrors(filters)
      .pipe(
        // catchError(err => of({ items: [] })),
        finalize(() => this.isLoading$.next(false))
      ).subscribe((x: IPagedResults<IJsonLog>) => {
        this.data$.next(x.items);
        this.totalRows$.next(x.totalCount);
      });
  }

  disconnect(): void {
    this.data$.complete();
    this.isLoading$.next(false);
    this.isLoading$.complete();
    this.totalRows$.complete();
    // this.depotContext.close();
  }

}
