import { HttpStatusCode } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';

import { AuthService, DepotValidators, HelperService, RoleGuard } from '@depot/@common';
import { UserRepositoryService } from '@depot/@data';
import { IClientError, IDepotContact, IDepotCustomGroupBy, IDepotLocation, IEmployee } from '@depot/custom';

import { BehaviorSubject, catchError, finalize, map, Observable, of } from 'rxjs';

import { groupBy } from 'underscore';

@Component({
  selector: 'depot-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  styles: [`

  `]
})
export class UserEditComponent implements OnInit {

  public timeZones$ = new BehaviorSubject<{ displayName: string; id: string }[]>([]);
  public locations$: Observable<IDepotCustomGroupBy<IDepotLocation[]>[]>;
  public formUser: UntypedFormGroup;
  public formLocation: UntypedFormGroup;
  @ViewChild(MatTabGroup) public tabGroup: MatTabGroup;
  public globalErrors$ = new BehaviorSubject<string[]>([]);
  public availableRoles: { name: string; description: string }[] = [
    { name: RoleGuard.UserAdmin, description: 'Allows managing other user information like name, email, and password resets' },
    { name: RoleGuard.AuditAdmin, description: 'Allows setting the "assigned to" for Audits' },
    { name: RoleGuard.PriceBookEditAdmin, description: 'Allows users to make changes to Pricebooks' },
    { name: RoleGuard.PickupAdmin, description: 'Allows users to manage Scrap Reports info for Dealer Returns' },
    { name: RoleGuard.PickupSuperAdmin, description: 'Allows users to delete Scrap Reports and associated pallets, images, weight tickets, etc. for Dealer Returns' },
    { name: RoleGuard.IncomingAdmin, description: 'Allows users to manage the pickup information for Dealer Returns' },
    { name: RoleGuard.WeightTicketAdmin, description: 'Allows users to manage the weight ticket information for Dealer Returns' },
    { name: RoleGuard.ScrapAdmin, description: 'Allows users to manage weighing/storing of incoming Dealer Returns' },
    { name: RoleGuard.OrderManager, description: 'Allows users to send Order QA emails and receive notifications for incoming email for their primary location' },
  ];

  constructor(
    private userRepo: UserRepositoryService,
    public dialogRef: MatDialogRef<UserEditComponent>,
    @Inject(MAT_DIALOG_DATA) public user: IEmployee,
    private fb: UntypedFormBuilder,
    private helper: HelperService,
    private authService: AuthService,
  ) {

    if (this.authService.isInRole(RoleGuard.SiteAdmin)) {
      this.availableRoles.push({ name: RoleGuard.SiteAdmin, description: 'Allows users to access logging information as well as some developer only features' });
    }
  }

  ngOnInit() {

    this.userRepo.getTimezones().subscribe(timezones => {
      this.timeZones$.next(timezones);
    });
    this.locations$ = this.userRepo.getDepotLocations().pipe(map(locations => {
      const grouping = groupBy(locations, x => x.categoryGroup);
      const keys = Object.keys(grouping);
      const data = keys.map(key => ({
        value: grouping[key],
        key: key,
        count: grouping[key].length,
      }));
      return data;
    }
    ));
    if (this.user === null) {
      this.user = {
        id: '',
        userName: '',
        email: '',
        lastName: '',
        firstName: '',
        roles: [RoleGuard.Admin],
        timeZone: 'Central Standard Time',
        location: 'WI',
        password: '',
        confirmPassword: '',
        depotCommunicationGroup: null
      };
      this.mapUserForm();
    } else {
      this.userRepo.getUser(this.user.id).pipe(catchError(error => {
        this.helper.mapErrors(error, null, this.formUser, this.globalErrors$);
        return of();
      })).subscribe(user => {
        this.user = user;
        this.mapUserForm();
        this.userRepo.getDepotContacts(this.user.id).pipe(catchError(error => {
          if (error.status !== HttpStatusCode.NotFound) {
            this.helper.mapErrors(error, null, this.formLocation, this.globalErrors$);
            return of(null);
          }
          return of({
            id: 0,
            userId: this.user.userName,
            email: this.user.email,
            name: this.user.firstName + ' ' + this.user.lastName,
            isDefault: false
          });
        })).subscribe(depotContact => {
          if (depotContact) {
            this.initializeContactSettings(depotContact);
          }
        });
      });


    }

  }

  private initializeContactSettings(depotContact: Partial<IDepotContact>) {
    this.formLocation = this.fb.group({
      id: [depotContact?.id],
      name: [depotContact?.name],
      userId: [depotContact?.userId, Validators.required],
      email: [depotContact?.email, DepotValidators.Email],
      isDefault: [depotContact?.isDefault],
      depotContactDepotLocations: [depotContact?.depotContactDepotLocations?.map(x => x.depotLocationId) ?? []]
    });
  }

  private mapUserForm() {
    this.formUser = this.fb.group({
      id: [this.user.id],
      email: [this.user.email, DepotValidators.Email],
      userName: [this.user.userName],
      firstName: [this.user.firstName],
      lastName: [this.user.lastName],
      timeZone: [this.user.timeZone],
      location: [this.user.location],
      roles: [this.user.roles],
      password: [''],
      confirmPassword: [''],
    }, {
      validators: [DepotValidators.MatchPassword()]
    });
  }

  save() {
    if (this.tabGroup.selectedIndex === 0) {
      if (!this.helper.validateForm(this.formUser)) {
        this.helper.showMessage('Form is invalid');
        return;
      }
      const user = this.formUser.getRawValue();
      this.globalErrors$.next([]);
      this.helper.IsGlobalSpinner$.set(true);
      this.userRepo.saveUser(user)
        .pipe(
          catchError((error: IClientError) => {
            this.helper.mapErrors(error, null, this.formUser, this.globalErrors$);
            return of();
          }),
          finalize(() => this.helper.IsGlobalSpinner$.set(false))
        )
        .subscribe(x => {
          this.dialogRef.close(x);
          this.helper.showMessage('Changes saved successful', 'success');
        });

    } else if (this.tabGroup.selectedIndex === 1) {
      if (!this.helper.validateForm(this.formLocation)) {
        this.helper.showMessage('Form is invalid');
        return;
      }
      const user = this.formLocation.getRawValue();
      user.depotContactDepotLocations = user.depotContactDepotLocations.map(x => ({ depotLocationId: x }));
      this.globalErrors$.next([]);
      this.helper.IsGlobalSpinner$.set(true);
      this.userRepo.saveDepotContacts(user)
        .pipe(
          catchError((error: IClientError) => {
            this.helper.mapErrors(error, null, this.formLocation, this.globalErrors$);
            return of();
          }),
          finalize(() => this.helper.IsGlobalSpinner$.set(false))
        )
        .subscribe(x => {
          this.dialogRef.close();
          this.helper.showMessage('Changes saved successful', 'success');
        });
    }

  }

  async delete() {
    if (await this.helper.confirmDialog('Are you sure you want to delete this user?  This action cannot be undone!')) {
      this.userRepo.deleteUser(this.user.id)
        .pipe(
          catchError((error: IClientError) => {
            this.helper.mapErrors(error, null, this.formUser, this.globalErrors$);
            this.helper.logger.error('Error saving part', error);
            return of();
          }),
          finalize(() => this.helper.IsGlobalSpinner$.set(false))
        ).subscribe(() => {
          this.dialogRef.close(null);
          this.helper.showMessage('User Deleted', 'success');
        });

    }

  }

}
