<mat-drawer-container hasBackdrop="true">
  <mat-drawer class="error_panel"
              closed
              (closedStart)="onPanelClose()"
              mode="over"
              position="end">
    <mat-toolbar color="secondary"
                 id="output"
                 style="top: 0; position: sticky;">
      <button mat-icon-button
              tabindex="-1"
              (click)="onErrorNav(-1)">
        <span class="fa fa-chevron-left"></span>
      </button>
      <span>Error Row {{ (selectedRow$ | async) + 1 }}</span>
      <!-- <button mat-button (click)="viewJson()">View Json</button> -->
      <button mat-icon-button
              tabindex="-1"
              (click)="onErrorNav(1)">
        <span class="fa fa-chevron-right"></span>
      </button>
      <div style="flex: 1 1 auto"></div>
      <mat-slide-toggle class="example-margin"
                        [(ngModel)]="isAutoScroll"
                        labelPosition="before">Auto Scroll</mat-slide-toggle>
      <button mat-icon-button
              tabindex="-1"
              (click)="sideBar.close()">
        <span class="fa fa-times"></span>
      </button>

    </mat-toolbar>
    <div #output
         class="error_output"></div>
  </mat-drawer>


  <!-- <mat-drawer-content>

  </mat-drawer-content> -->


  <div class="content mat-elevation-z8">

    <!--  -->
    <mat-expansion-panel #searchPanel>
      <mat-expansion-panel-header>
        <mat-panel-title style="line-height:32px; vertical-align:middle;">
          Filters
          <button mat-button
                  color="primary"
                  (click)="loadData($event)">Search</button>
          <button mat-button
                  color="primary"
                  (click)="resetFilters($event)">Reset</button>

        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent
                   *ngIf="(cachedFilters$ | async) as cache">
        <mat-form-field>
          <mat-label>Start Date</mat-label>
          <input matInput
                 [(ngModel)]="cache.startDate"
                 [matDatepicker]="startDate">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix
                                 [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>End Date</mat-label>
          <input matInput
                 [(ngModel)]="cache.endDate"
                 [matDatepicker]="endDate">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix
                                 [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Message</mat-label>
          <input matInput
                 type="text"
                 [(ngModel)]="cache.message">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Applications</mat-label>
          <mat-select [(ngModel)]="cache.applications"
                      multiple="true">
            <mat-option [value]="val"
                        *ngFor="let val of applications$ | async">
              {{val}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Levels</mat-label>
          <mat-select multiple="true"
                      [(ngModel)]="cache.levels">
            <mat-option value="DEBUG"> Debug </mat-option>
            <mat-option value="INFO"> Info </mat-option>
            <mat-option value="WARN"> Warn </mat-option>
            <mat-option value="ERROR"> Error </mat-option>
            <mat-option value="FATAL"> Fatal </mat-option>
          </mat-select>
        </mat-form-field>

      </ng-template>
    </mat-expansion-panel>

    <mat-table [dataSource]="dataSource"
               matSort
               [matSortActive]="(cachedFilters$ | async).orderBy?.split(' ')[0]"
               [matSortDirection]="(cachedFilters$ | async).orderBy?.split(' ')[1]"
               (matSortChange)="sortFilters()">

      <ng-container matColumnDef="level">
        <mat-header-cell mat-header-cell
                         *matHeaderCellDef
                         mat-sort-header>Level</mat-header-cell>
        <mat-cell mat-cell
                  [ngClass]="row.level.toLowerCase() + '-label'"
                  *matCellDef="let row">{{row.level}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="time">
        <mat-header-cell mat-header-cell
                         *matHeaderCellDef
                         mat-sort-header>Time</mat-header-cell>
        <mat-cell mat-cell
                  *matCellDef="let row">{{row.time | date:'yyyy-MM-dd hh:mm:ss a'}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="application">
        <mat-header-cell mat-header-cell
                         *matHeaderCellDef
                         mat-sort-header>Application</mat-header-cell>
        <mat-cell mat-cell
                  *matCellDef="let row">{{row.application}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="user">
        <mat-header-cell mat-header-cell
                         *matHeaderCellDef
                         mat-sort-header>User</mat-header-cell>
        <mat-cell mat-cell
                  *matCellDef="let row">{{row.user}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="message">
        <mat-header-cell mat-header-cell
                         *matHeaderCellDef
                         mat-sort-header>Message</mat-header-cell>
        <mat-cell mat-cell
                  *matCellDef="let row">{{row.message}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="isClient">
        <mat-header-cell mat-header-cell
                         *matHeaderCellDef
                         mat-sort-header>Is Client</mat-header-cell>
        <mat-cell mat-cell
                  *matCellDef="let row">{{row.isClient}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dataSource.columns; sticky: true"
                      class="table-header-primary"></mat-header-row>

      <mat-row [ngClass]="(selectedRow$ | async)===i ? 'grid-row-selected':''"
               class="grid-row"
               (click)="onRowSelect(row, i)"
               *matRowDef="let row; let i = index; columns: dataSource.columns;"></mat-row>

      <div class="no-data-row"
           *matNoDataRow>
        No logs found
      </div>
    </mat-table>

    <mat-paginator [disabled]="dataSource?.isLoading$ | async"
                   (page)="loadData()"
                   [pageSize]="(cachedFilters$ | async)?.takeCount"
                   [pageSizeOptions]="[50, 100, 250, 500]">
    </mat-paginator>
  </div>

</mat-drawer-container>
