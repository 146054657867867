<h1 mat-dialog-title>User Edit</h1>

<div mat-dialog-content>
  <mat-tab-group mat-stretch-tabs="false">
    <mat-tab label="User Info">
      <form *ngIf="formUser; else loading"
            (ngSubmit)="save()"
            [formGroup]="formUser">


        <div class="tw-pt-3 tw-flex tw-flex-col">
          <div class="dp-sticky-item">
            <mat-error *ngFor="let err of globalErrors$ | async">&bull; {{err}}</mat-error>
          </div>
          <div>
            <mat-form-field [class.readonly]="user.id !== ''">
              <mat-label>Username</mat-label>
              <input matInput
                     data-lpignore="true"
                     formControlName="userName"
                     [readonly]="user.id  !== ''"
                     required
                     type="text" />
              <mat-error [depot-form-error]="formUser"
                         errorControlName="userName">
              </mat-error>
            </mat-form-field>
          </div>

          <div class="tw-inline-flex tw-gap-1">
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput
                     data-lpignore="true"
                     formControlName="email"
                     required
                     type="email" />
              <mat-error [depot-form-error]="formUser"
                         errorControlName="email">
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>First Name</mat-label>
              <input matInput
                     data-lpignore="true"
                     formControlName="firstName"
                     required
                     type="text" />
              <mat-error [depot-form-error]="formUser"
                         errorControlName="firstName">
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input matInput
                     data-lpignore="true"
                     formControlName="lastName"
                     required
                     type="text" />
              <mat-error [depot-form-error]="formUser"
                         errorControlName="lastName">
              </mat-error>
            </mat-form-field>
          </div>

          <div class="tw-inline-flex tw-gap-1">
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input matInput
                     data-lpignore="true"
                     formControlName="password"
                     type="password"
                     [required]="user.id===''"
                     autocomplete="off" />
              <mat-error [depot-form-error]="formUser"
                         errorControlName="password">
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Confirm Password</mat-label>
              <input matInput
                     data-lpignore="true"
                     formControlName="confirmPassword"
                     type="password"
                     [required]="user.id===''"
                     autocomplete="off" />
              <mat-error [depot-form-error]="formUser"
                         errorControlName="confirmPassword">
              </mat-error>
            </mat-form-field>
          </div>

          <div class="tw-inline-flex tw-gap-1">
            <mat-form-field *ngIf="timeZones$ | async as timeZones"
                            style="width: 400px;">
              <mat-label>Time Zones</mat-label>
              <mat-select required
                          formControlName="timeZone">
                <mat-option *ngFor="let timeZone of timeZones"
                            [value]="timeZone.id">
                  {{timeZone.displayName}}
                </mat-option>
              </mat-select>
              <mat-error [depot-form-error]="formUser"
                         errorControlName="timeZone">
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Primary Location</mat-label>
              <mat-select required
                          formControlName="location">
                <mat-option value="WI">
                  Wisconsin
                </mat-option>
                <mat-option value="TN">
                  Tennessee
                </mat-option>
                <mat-option value="CO">
                  Colorado
                </mat-option>
              </mat-select>
              <mat-error [depot-form-error]="formUser"
                         errorControlName="location">
              </mat-error>
            </mat-form-field>
          </div>

          <mat-selection-list formControlName="roles">
            <div mat-subheader>Roles</div>
            <mat-list-option disabled
                             togglePosition="before"
                             value="Admin">
              <div matListItemTitle>Admin</div>
              <div matListItemLine>General permission for employees. This is needed to access the internal site</div>

            </mat-list-option>
            <mat-divider></mat-divider>
            @for (role of availableRoles; track $index) {
            <mat-list-option [value]="role.name"
                             togglePosition="before">

              <div matListItemTitle>{{role.name}}</div>
              <div matListItemLine>{{role.description}}</div>
            </mat-list-option>
            <mat-divider></mat-divider>
            }
          </mat-selection-list>

          <mat-error [depot-form-error]="formUser"
                     errorControlName="roles">
          </mat-error>


        </div>
      </form>
    </mat-tab>

    <mat-tab label="Communication Groups"
             [disabled]="!formLocation">
      <form *ngIf="formLocation; else loading"
            (ngSubmit)="save()"
            [formGroup]="formLocation">
        <div class="tw-pt-3">
          <div class="dp-sticky-item">
            <mat-error *ngFor="let err of globalErrors$ | async">&bull; {{err}}</mat-error>
          </div>


          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput
                   formControlName="name"
                   required
                   type="text" />
            <mat-error [depot-form-error]="formLocation"
                       errorControlName="name">
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput
                   data-lpignore="true"
                   formControlName="email"
                   required
                   type="email" />
            <mat-error [depot-form-error]="formLocation"
                       errorControlName="email">
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Is Default</mat-label>
            <mat-select required
                        formControlName="isDefault">
              <mat-option [value]="true">
                Yes
              </mat-option>
              <mat-option [value]="false">
                No
              </mat-option>
            </mat-select>
            <mat-error [depot-form-error]="formLocation"
                       errorControlName="isDefault">
            </mat-error>
            <mat-hint>If a return is submitted to a location that isn't defined this contact will be used</mat-hint>
          </mat-form-field>


          <mat-selection-list class="zdp-two-column-list"
                              formControlName="depotContactDepotLocations">
            @for (locationGroup of locations$ | async; track locationGroup.key) {
            <div mat-subheader>{{locationGroup.key}}</div>
            <div class="tw-grid tw-grid-cols-[repeat(auto-fill,_minmax(225px,_1fr))]">
              @for(location of locationGroup.value; track location.id){
              <mat-list-option [value]="location.id"
                               checkboxPosition="before">

                <div mat-line>{{location.name}}</div>
              </mat-list-option>
              }
            </div>
            }
          </mat-selection-list>
          <mat-error [depot-form-error]="formLocation"
                     errorControlName="depotContactDepotLocations">
          </mat-error>
        </div>
      </form>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions>
  <button mat-raised-button
          color="warn"
          *ngIf="user.id !== ''"
          (click)="delete()">Delete User</button>
  <span class="dp-flex-space"></span>
  <button mat-raised-button
          (click)="dialogRef.close(null)">Cancel</button>
  <button mat-raised-button
          color="primary"
          (click)="save()">Save</button>

</div>

<ng-template #loading>
  <div>
    Loading user information...

    <mat-spinner style="margin:0 auto;"></mat-spinner>

  </div>
</ng-template>
