import { Location } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';

import { AuthService, HelperService, Json2Table, SettingsService, StackFormat } from '@depot/@common';
import { ErrorRepositoryService, IErrorDashboard } from '@depot/@data';
import { IDepotCustomGroupBy, IJsonLog } from '@depot/custom';

import { BehaviorSubject, finalize, first, fromEvent, map, Observable, ReplaySubject, Subject } from 'rxjs';

import { endOfDay, format, subDays, subMonths, subWeeks } from 'date-fns';
import { countBy, groupBy, map as _map, sortBy, unique } from 'underscore';

@Component({
  selector: 'depot-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatDrawerContainer) sideBar: MatDrawerContainer;
  get cache_key() { return 'log_dashboard'; }

  get defaultFilters(): IErrorDashboard {
    return {
      dateFilter: 1,
      filterItems: [],
      application: ''
    };
  }

  public logData$ = new BehaviorSubject<IJsonLog[]>([]);
  public logAggregates$: Observable<{ name: string; value: number }[]>;
  public logChart$: Observable<{ name: string; series: { name: string; value: number }[] }[]>;
  public logUsers$: Observable<IDepotCustomGroupBy<IJsonLog>[]>;
  public logFrequent$: Observable<IDepotCustomGroupBy<IJsonLog>[]>;
  public logApplication$: Observable<IDepotCustomGroupBy<IJsonLog>[]>;
  public applications$: Observable<IDepotCustomGroupBy<IJsonLog>[]>;
  public logUrls$: Observable<IDepotCustomGroupBy<IJsonLog>[]>;
  public logLatest$: Observable<IJsonLog[]>;
  public cachedFilters$ = new BehaviorSubject<IErrorDashboard>(this.defaultFilters);
  public jsonTable$ = new Subject();
  public isAutoScroll = true;
  public selectedIndex$ = new BehaviorSubject<number>(-1);
  public selectedRows$ = new BehaviorSubject<any[]>([]);
  private filteredLogs$ = new ReplaySubject<IJsonLog[]>(1);


  public colorScheme = [
    { name: 'Debug', value: '#919191' },
    { name: 'Info', value: '#007bff' },
    { name: 'Warn', value: '#ffc107' },
    { name: 'Error', value: '#dc3545' },
    { name: 'Fatal', value: '#820000' },
    { name: 'Total', value: '' },
  ];

  constructor(
    private errorRepo: ErrorRepositoryService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private helper: HelperService,
    public activatedRoute: ActivatedRoute,
    private location: Location,
    private destroyRef: DestroyRef,
  ) {
    // todo add paging to the grid
  }



  ngOnInit() {

    this.settingsService.dashboardSettings<IErrorDashboard>(this.cache_key).then(cachedData => {
      this.cachedFilters$.next(Object.assign(this.defaultFilters, cachedData));


      this.logAggregates$ = this.filteredLogs$.pipe(
        map(logs => {
          const groups = groupBy(logs, x => x.level.toUpperCase());
          const data = [
            { name: 'TOTAL', value: logs.length },
            { name: 'FATAL', value: groups.hasOwnProperty('FATAL') ? groups['FATAL'].length : 0 },
            { name: 'ERROR', value: groups.hasOwnProperty('ERROR') ? groups['ERROR'].length : 0 },
            { name: 'WARN', value: groups.hasOwnProperty('WARN') ? groups['WARN'].length : 0 },
            { name: 'INFO', value: groups.hasOwnProperty('INFO') ? groups['INFO'].length : 0 },
            { name: 'DEBUG', value: groups.hasOwnProperty('DEBUG') ? groups['DEBUG'].length : 0 },
          ];
          const missingFilters = unique(logs.filter(x => data.map(y => y.name).indexOf(x.level.toUpperCase()) === -1).map(x => x.level));
          if (missingFilters.length > 0) {
            this.helper.logger.warn('Missing error parameter on dashboard', null, missingFilters);
          }
          return data;
        })

      );

      this.applications$ = this.logData$.pipe(map(logs => this.groupByKey(logs, 'application')));

      this.logChart$ = this.filteredLogs$.pipe(
        map(logs => {
          const dateFilter = this.cachedFilters$.getValue().dateFilter;
          let groupByFormat: string;
          if (dateFilter === 1) {
            groupByFormat = 'h a';
          } else if (dateFilter === 5) {
            groupByFormat = 'yyyy-II';
          } else {
            groupByFormat = 'PPP';
          }
          const grouping = groupBy(logs, row => format(row.time, groupByFormat));
          // console.log(Object.groupBy(logs, row => format(row.time, groupByFormat)));
          return Object.keys(grouping).map(dateKey => ({
            name: dateKey,
            series: _map(countBy(grouping[dateKey].map(x => x.level)), (value, key) => ({
              name: key,
              value: value
            }),
            )
          }),
          ).reverse();

        }),

      );

      this.logUsers$ = this.filteredLogs$.pipe(map(logs => this.groupByKey(logs, 'user')));
      this.logUrls$ = this.filteredLogs$.pipe(map(logs => this.groupByKey(logs, 'url')));
      this.logFrequent$ = this.filteredLogs$.pipe(map(logs => this.groupByKey(logs, 'message')));
      this.logApplication$ = this.filteredLogs$.pipe(map(logs => this.groupByKey(logs, 'application')));

      this.logLatest$ = this.filteredLogs$.pipe(
        map(logs => logs.slice(0, 15).map(x => {
          (<any>x).value = x;
          return x;
        }))
      );

      this.filteredLogs$.pipe(first(x => x.length > 0),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe((logs: IJsonLog[]) => {
        const id = this.activatedRoute.snapshot.params['id'];
        if (id) {
          const data = logs.filter(x => x.id === id);
          if (data.length > 0) {
            this.showError([{ value: data[0] }], 0);
          }
        }
      });

      this.filterChange();
      this.helper.IsGlobalBar$.set(false);
    });

  }
  ngAfterViewInit() {
    fromEvent(document, 'keyup')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x: KeyboardEvent) => {
        x.preventDefault();
        const index = this.selectedIndex$.getValue();
        if (x && (x.key === 'ArrowLeft' || x.key === 'ArrowUp')) {
          this.showError(null, index - 1);
        } else if (x && (x.key === 'ArrowRight' || x.key === 'ArrowDown')) {
          this.showError(null, index + 1);
        }

      });

  }

  public filterChange() {
    this.helper.IsGlobalSpinner$.set(true);
    const filters = this.cachedFilters$.getValue();
    const startDate = this.mapDateFilter(filters.dateFilter);
    const endDate = endOfDay(new Date());
    this.settingsService.dashboardSettings<IErrorDashboard>(this.cache_key, filters);
    this.errorRepo.getDashboardData({ startDate, endDate })
      .pipe(
        finalize(() => this.helper.IsGlobalSpinner$.set(false))
      )
      .subscribe(response => {
        response.forEach(x => {
          x.body = this.jsonTryParse(x.body);
        });
        this.filteredLogs$.next(response);
        this.logData$.next(response);
        this.filterGroup(null);
      });
  }

  public filterGroup(level: string | null) {
    if (level === 'TOTAL') {
      return;
    }
    const filters = this.cachedFilters$.getValue();
    if (filters.filterItems.includes(level)) {
      filters.filterItems = filters.filterItems.filter(x => x !== level);
    } else if (level) {
      filters.filterItems.push(level);
    }
    this.settingsService.dashboardSettings<IErrorDashboard>(this.cache_key, filters);

    const value = this.logData$.getValue()
      .filter(x => filters.filterItems.length === 0 || filters.filterItems.includes(x.level.toUpperCase()))
      .filter(x => filters.application === '' || x.application.toUpperCase() === filters.application.toUpperCase());
    this.filteredLogs$.next(value);

  }

  private mapDateFilter(filter: number) {
    const date = new Date();
    switch (filter) {
      case 1:
        return subDays(date, 1);
      case 2:
        return subWeeks(date, 1);
      case 3:
        return subWeeks(date, 2);
      case 4:
        return subMonths(date, 1);
      case 5:
        return new Date(2019, 1, 1);
      case 6:
        break;

    }
  }

  private groupByKey(logs: IJsonLog[], groupKey: keyof IJsonLog) {
    const grouping = groupBy(logs, x => x.body[groupKey]);
    const mapped = Object.keys(grouping).map((key) => ({
      value: grouping[key].at(0),
      key: key,
      count: grouping[key].length
    }));
    return sortBy(mapped, x => x.count).reverse().splice(0, 15);
  }

  public showError(logs: any[], index: number) {
    if (logs) {
      this.selectedRows$.next(logs);
    }
    let data = this.selectedRows$.getValue()[index];
    if (data) {
      this.location.go('/admin/dashboard/' + data.value.id);
      data = data.value.body;
      this.selectedIndex$.next(index);
      // this.errorRepo.formatStack(data.stackTrace).subscribe(x => {
      // data.exception = this.netStack(data.exception);
      if (data.isClient === true && data.stackTrace) {
        data.stackTrace = StackFormat.jsStack(data.stackTrace);

      } else if (data.exception) {
        data.exception = StackFormat.netStack(data.exception);
      }
      this.jsonTable$.next(Json2Table.buildTable(data).outerHTML);

      this.sideBar.open();
      if (this.isAutoScroll) {
        document.getElementById('output').scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
    // });

  }

  public onPanelClose() {
    this.location.go('/admin/dashboard');
  }

  private jsonTryParse(data: string) {
    try {
      return JSON.parse(data);
    } catch (err) {
    }
    return {};
  }

  ngOnDestroy() {

    this.cachedFilters$.complete();

  }


  // onErrorNav(increment: number) {
  //   const datasource = this.dataSource.data$.getValue();
  //   const nextIndex = this.selectedRows$.getValue() + increment;
  //   const data = datasource[nextIndex];
  //   if (data) {
  //     this.onRowSelect(data, nextIndex);
  //     if (this.isAutoScroll) {
  //       document.getElementById('output').scrollIntoView({ behavior: 'smooth', block: 'end' });
  //     }
  //   }
  // }


  /** Based on the screen size, switch from standard to one column per row */
  // cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
  //   map(({ matches }) => {
  //     if (matches) {
  //       return [
  //         { title: 'Card 1', cols: 1, rows: 1 },
  //         { title: 'Card 2', cols: 1, rows: 1 },
  //         { title: 'Card 3', cols: 1, rows: 1 },
  //         { title: 'Card 4', cols: 1, rows: 1 }
  //       ];
  //     }

  //     return [
  //       { title: 'Card 1', cols: 2, rows: 1 },
  //       { title: 'Card 2', cols: 1, rows: 1 },
  //       { title: 'Card 3', cols: 1, rows: 2 },
  //       { title: 'Card 4', cols: 1, rows: 1 }
  //     ];
  //   })
  // );

}


