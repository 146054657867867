import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { HelperService, RoleGuard } from '@depot/@common';
import { UserRepositoryService } from '@depot/@data';
import { UserEditComponent } from '@depot/admin';
import { IDepotCustomGroupBy, IDepotLocation, IEmployee } from '@depot/custom';

import { BehaviorSubject, first, map, Observable, Subject } from 'rxjs';

import { groupBy, sortBy } from 'underscore';

@Component({
  selector: 'depot-user-grid',
  templateUrl: './user-grid.component.html',
  styleUrls: ['./user-grid.component.scss']
})
export class UserGridComponent implements OnInit, OnDestroy {
  public columns = ['edit', 'username', 'firstName', 'lastName', 'email', 'roles', 'location', 'timeZone'];
  public users$ = new BehaviorSubject<IEmployee[]>([]);
  public filteredUsers$ = new Subject<IEmployee[]>();
  @ViewChild(MatTable) dataGrid: MatTable<any>;
  public locations$: Observable<IDepotCustomGroupBy<IDepotLocation[]>[]>;

  public roles = [
    RoleGuard.UserAdmin,
    RoleGuard.AuditAdmin,
    RoleGuard.PriceBookEditAdmin,
    RoleGuard.PickupAdmin,
    RoleGuard.PickupSuperAdmin,
    RoleGuard.IncomingAdmin,
    RoleGuard.WeightTicketAdmin,
    RoleGuard.ScrapAdmin,
    RoleGuard.OrderManager,
  ];
  public filters = {
    userName: '',
    firstName: '',
    lastName: '',
    role: '',
    communicationGroup: '',
  };

  constructor(
    private userRepo: UserRepositoryService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog,
    private helper: HelperService,
  ) {

  }

  ngOnInit() {
    this.userRepo.getUsers().subscribe(x => {
      this.users$.next(x);
      this.loadData();
      const id = this.activatedRoute.snapshot.params['id'];
      if (id) {
        this.navigate(id);
      }
      this.helper.IsGlobalBar$.set(false);
    });

    this.locations$ = this.userRepo.getDepotLocations().pipe(map(locations => {
      const grouping = groupBy(locations, x => x.categoryGroup);
      const keys = Object.keys(grouping);
      const data = keys.map(key => ({
        value: grouping[key],
        key: key,
        count: grouping[key].length,
      }));
      return data;
    }
    ));

  }

  public loadData(e?: Event | null) {
    if (e) {
      e.stopPropagation();
    }
    let users = this.users$.getValue();
    users = sortBy(sortBy(users, x => x.lastName), x => x.firstName);
    if (this.filters.userName.length > 0) {
      users = users.filter(x => x.userName.toLowerCase().includes(this.filters.userName.toLowerCase()));
    }
    if (this.filters.firstName.length > 0) {
      users = users.filter(x => x.firstName.toLowerCase().includes(this.filters.firstName.toLowerCase()));

    }
    if (this.filters.lastName.length > 0) {
      users = users.filter(x => x.lastName.toLowerCase().includes(this.filters.lastName.toLowerCase()));

    }

    if (this.filters.role.length > 0) {
      users = users.filter(x => x.roles.includes(this.filters.role));
    }

    if (this.filters.communicationGroup.length > 0) {
      users = users.filter(x => x.depotCommunicationGroup?.some(y => y.name === this.filters.communicationGroup) === true);
    }

    this.filteredUsers$.next(users);
  }

  public resetFilters(e?: Event | null) {
    if (e) {
      e.stopPropagation();
    }
    this.filters = { userName: '', firstName: '', lastName: '', role: '', communicationGroup: '' };
    this.loadData();
  }

  ngOnDestroy() {
  }

  addNew() {
    this.dialog.open(UserEditComponent, {
      data: null,
      disableClose: true,
      minHeight: '75vh',
      minWidth: '730px'
    }).afterClosed()
      .pipe(first())
      .subscribe((savedUser: IEmployee) => {
        if (savedUser) {
          const users = this.users$.getValue();
          users.push(savedUser);
          this.users$.next(users);
          this.dataGrid.renderRows();
          this.loadData();

        }
        // this.partData$.value.partImages.find(x => x.id === newPartImage.id).fileStream = newPartImage.fileStream;
      });


  }

  navigate(id: string) {
    this.location.go('/admin/users/' + id);

    const user = this.users$.getValue().find(x => x.id === id);
    if (!user) {
      return;
    }

    this.dialog.open(UserEditComponent, {
      data: user,
      disableClose: true,
      minHeight: '75vh',
      minWidth: '730px'
    }).afterClosed()
      .pipe(first())
      .subscribe(savedUser => {
        this.location.go('/admin/users');
        if (savedUser) {
          Object.assign(user, savedUser);
        } else {
          // const users = this.users$.getValue();
          // users.splice(users.indexOf(user), 1);
          // this.users$.next(users);

          this.userRepo.getUsers().subscribe(x => {
            this.users$.next(x);
            this.dataGrid.renderRows();
            this.loadData();
          });

        }
        // this.partData$.value.partImages.find(x => x.id === newPartImage.id).fileStream = newPartImage.fileStream;
      });

  }

}
